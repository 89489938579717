/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../node_modules/ng2-toasty/src/toasty.component.ngfactory";
import * as i3 from "ng2-toasty/src/toasty.component";
import * as i4 from "ng2-toasty/src/toasty.service";
import * as i5 from "../../node_modules/@ngx-loading-bar/core/ngx-loading-bar-core.ngfactory";
import * as i6 from "@ngx-loading-bar/core";
import * as i7 from "./app.component";
import * as i8 from "./core/services/eddressFacade.service";
import * as i9 from "@ngx-translate/core";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "ng2-toasty", [], null, null, null, i2.View_ToastyComponent_0, i2.RenderType_ToastyComponent)), i0.ɵdid(3, 114688, null, 0, i3.ToastyComponent, [i4.ToastyConfig, i4.ToastyService], { position: [0, "position"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "ngx-loading-bar", [], [[2, "loading-bar-fixed", null]], null, null, i5.View_LoadingBarComponent_0, i5.RenderType_LoadingBarComponent)), i0.ɵdid(5, 49152, null, 0, i6.LoadingBarComponent, [i6.LoadingBarService], { includeSpinner: [0, "includeSpinner"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = "top-right"; _ck(_v, 3, 0, currVal_0); var currVal_2 = false; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 5).fixed; _ck(_v, 4, 0, currVal_1); }); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i7.AppComponent, [i8.EddressFacadeService, i9.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
