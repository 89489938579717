import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class UIStateService {
    constructor() {
        this.isMenuCollapsedSubject = new BehaviorSubject(true);
        this.isMenuCollapsed = this.isMenuCollapsedSubject.asObservable();
    }
    updateMenuCollapsed(isCollapsed) {
        this.isMenuCollapsedSubject.next(isCollapsed);
    }
}
UIStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UIStateService_Factory() { return new UIStateService(); }, token: UIStateService, providedIn: "root" });
