export const V1 = 'v1';
export const CONFIG = 'config/';
export const MARKET = 'market/';
export const ADMIN = 'admin/';
export const ORDERS = 'orders/';
export const MERCHANTS = 'merchants/';
export const V1_MERCHANTS = 'v1/merchants/';
export const UPLOAD_LOGO_ICON = 'upload-logo-icon';
export const UPDATE_LOGO_ICON = 'update-logo-icon';
export const DELETE_LOGO_ICON = 'delete-logo-icon';
export const UPDATE_LOGO_ICON_IS_ENABLED_FLAG = 'update-logo-icon-is-enabled-flag';
export const GET_LOGO_ICON = 'get-logo-icon';
export const MARKET_ADMIN_CONFIG = MARKET + ADMIN + CONFIG;
export const REFERRAL_PROMO_CODE_REPORT = 'v1/marketplace/referral-promo-code-report';
export const EXCHANGE_RATE = MARKET + ADMIN + CONFIG + 'exchangeRate';
export const THIRD_PARTY_EXCHANGE_RATE_MARGIN_AMOUNT = MARKET + ADMIN + CONFIG + 'thirdPartyExchangeRateMarginAmount';
export const THIRD_PARTY_EXCHANGE_RATE_STATUS = MARKET + ADMIN + CONFIG + 'thirdPartyExchangeRateStatus';
export const VALIDATE_PHONE_NUMBER = MARKET + ADMIN + CONFIG + 'validate-phone-number';
export const COLLECTIONS = MARKET + ADMIN + 'collections';
export const COLLECTIONS_ALL = COLLECTIONS + '/all';
export const COLLECTION_PRODUCTS = '/collectionProducts';
export const EXCEL_REPORT_V1 = V1 + '/excelReport' + COLLECTION_PRODUCTS;
export const PRODUCT_COLLECTION_DATA = COLLECTIONS + '/productCollectionData';
export const PRODUCT_SORT_ORDER = COLLECTIONS + '/sortOrder';
export const MARKET_ADMIN_MERCHANTS = MARKET + ADMIN + MERCHANTS;
export const MARKET_ADMIN_MERCHANTS_CAP_LIMIT = MARKET_ADMIN_MERCHANTS + 'caplimit';
export const MARKET_ADMIN_ORDERS_STORE_CONSUMED_SCANNED_BAG = MARKET + ADMIN + ORDERS + 'consumed-scanned-bags';
export const MARKET_ADMIN_CONFIG_ONLINE_PAYMENT_SURCHARGE_PERCENTAGE_CONFIG = 'market/admin/config/online-payment-surcharge-percentage-config/{thirdPartyUid}/{operationUid}'
