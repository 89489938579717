/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./authentication-layout.component";
var styles_AuthenticationLayoutComponent = [];
var RenderType_AuthenticationLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AuthenticationLayoutComponent, data: {} });
export { RenderType_AuthenticationLayoutComponent as RenderType_AuthenticationLayoutComponent };
export function View_AuthenticationLayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AuthenticationLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_AuthenticationLayoutComponent_0, RenderType_AuthenticationLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i2.AuthenticationLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthenticationLayoutComponentNgFactory = i0.ɵccf("app-dashboard", i2.AuthenticationLayoutComponent, View_AuthenticationLayoutComponent_Host_0, {}, {}, []);
export { AuthenticationLayoutComponentNgFactory as AuthenticationLayoutComponentNgFactory };
