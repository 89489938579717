import * as i0 from "@angular/core";
export class LocalStorageService {
    constructor() { }
    setItem(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    getItem(key) {
        return JSON.parse(localStorage.getItem(key));
    }
    removeItem(key) {
        localStorage.removeItem(key);
    }
    clearItems() {
        localStorage.clear();
    }
}
LocalStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(); }, token: LocalStorageService, providedIn: "root" });
