import { ToastyService } from 'ng2-toasty';
import * as i0 from "@angular/core";
import * as i1 from "ng2-toasty/src/toasty.service";
export class NotificationService {
    constructor(toastyService) {
        this.toastyService = toastyService;
    }
    errorNotification(message) {
        this.toastyService.error({
            title: '',
            msg: message,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        });
    }
    successNotification(message) {
        this.toastyService.success({
            title: '',
            msg: message,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        });
    }
    infoNotification(message) {
        this.toastyService.info({
            title: '',
            msg: message,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        });
    }
    waitNotification(message) {
        this.toastyService.wait({
            title: '',
            msg: message,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        });
    }
    warningNotification(message) {
        this.toastyService.warning({
            title: '',
            msg: message,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        });
    }
    playSound() {
        const audio = new Audio();
        audio.src = '../../../assets/audio/success.mp3';
        audio.load();
        const playPromise = audio.play();
        if (playPromise !== null) {
            playPromise.catch(() => {
                audio.play();
            });
        }
    }
    playWarningSound() {
        const audio = new Audio();
        audio.src = '../../../assets/audio/warning.wav';
        audio.load();
        audio.play();
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.ToastyService)); }, token: NotificationService, providedIn: "root" });
