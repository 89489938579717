export enum OrderStatusEnum {
	NEW = 'NEW',
	READY = 'READY',
	ASSIGNED = 'ASSIGNED',
	CONFIRMED = 'CONFIRMED',
	OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
	PICKING_UP = 'PICKING_UP',
	PICKED_UP = 'PICKED_UP',
	DELIVERING = 'DELIVERING',
	DELIVERED = 'DELIVERED',
	RETURNING = 'RETURNING',
	RETURNED = 'RETURNED',
	COMPLETE = 'COMPLETE',
	FAILED = 'FAILED',
	RETURNS = 'RETURNS',
	PICKUP_FAILED = 'PICKUP_FAILED',
	DELIVERY_FAILED = 'DELIVERY_FAILED',
	RETURN_FAILED = 'RETURN_FAILED',
	CANCELED = 'CANCELED',
	RETURN_CANCELED = 'RETURN_CANCELED',
	DONE = 'DONE',
	NEW_CANCELED = 'NEW_CANCELED',
	PENDING_RETURN = 'PENDING_RETURN',
	RETURNED_TO_CUSTOMER = 'RETURNED_TO_CUSTOMER',
  PAYMENT_PENDING = "PAYMENT_PENDING"
}
export enum OrderStateLabelEnum {
	New = 'New',
	Complete = 'Complete',
	Canceled = 'Canceled',
	Failed = 'Failed'
}
