import { InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import 'rxjs/add/observable/of';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export const graphqlBaseUrl = new InjectionToken('graphqlBaseUrl');
export class GraphqlService {
    constructor(http, _graphqlBaseUrl) {
        this.http = http;
        this._graphqlBaseUrl = _graphqlBaseUrl;
        this._typeof = typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol'
            ? function (obj) {
                return typeof obj;
            }
            : function (obj) {
                return obj &&
                    typeof Symbol === 'function' &&
                    obj.constructor === Symbol &&
                    obj !== Symbol.prototype
                    ? 'symbol'
                    : typeof obj;
            };
    }
    getQuery(fields) {
        return this.encodeOperation('', fields);
    }
    executeQuery(query) {
        const param = {
            query: String(query)
        };
        return this.http
            .post(`${this._graphqlBaseUrl}`, param)
            .pipe(map((a) => a.data));
    }
    encodeOperation(type, _nameOrFields, _fieldsOrNil = null) {
        var name = _nameOrFields;
        var fields = _fieldsOrNil;
        if (!_fieldsOrNil &&
            (typeof _nameOrFields === 'undefined'
                ? 'undefined'
                : this._typeof(_nameOrFields)) === 'object') {
            name = null;
            fields = _nameOrFields;
        }
        var parts = [];
        // stringifying the main query object
        var fieldset = this.encodeFieldset(fields);
        if (name) {
            parts.push(type + ' ' + name + fieldset);
        }
        else {
            parts.push('' + type + fieldset);
        }
        return parts.join('\n');
    }
    encodeFieldset(fields) {
        var parts = [];
        if (fields) {
            parts.push(this.encodeFields(fields));
        }
        return '{' + parts.join(',') + '}';
    }
    encodeFields(fields, isNestedObject = false) {
        if (!fields ||
            (typeof fields === 'undefined' ? 'undefined' : this._typeof(fields)) !==
                'object') {
            throw new Error('fields cannot be "' + fields + '"');
        }
        var encoded = Object.keys(fields)
            .filter(function (key) {
            return fields.hasOwnProperty(key) && fields[key];
        })
            .map((key) => this.encodeField(key, fields[key], isNestedObject));
        if (encoded.length === 0) {
            throw new Error('fields cannot be empty');
        }
        return encoded.join(',');
    }
    encodeField(key, val, isNestedObject = false) {
        if ((typeof val === 'undefined' ? 'undefined' : this._typeof(val)) !==
            'object') {
            return key;
        }
        var parts = [key];
        if (val.operation) {
            parts.push(':' + val.operation);
            val = val.query;
        }
        if (val.field) {
            parts.push(':' + val.field);
        }
        if (val.params) {
            parts.push(this.encodeParams(val.params, val.feed));
        }
        if (val.fields) {
            parts.push(this.encodeFieldObject(val.fields));
        }
        if (isNestedObject && this._typeof(val) === 'object') {
            parts.push('{' + this.encodeFields(val, true) + '}');
        }
        return parts.join('');
    }
    encodeFieldObject(fields) {
        if (!fields ||
            (typeof fields === 'undefined' ? 'undefined' : this._typeof(fields)) !==
                'object') {
            throw new Error('fields cannot be "' + fields + '"');
        }
        var encoded = Object.keys(fields)
            .filter(function (key) {
            return fields.hasOwnProperty(key) && fields[key];
        })
            .map((key) => {
            if ((typeof fields[key] === 'undefined'
                ? 'undefined'
                : this._typeof(fields[key])) !== 'object') {
                return key;
            }
            var parts = [key];
            parts.push('{' + this.encodeFields(fields[key], true) + '}');
            return parts.join('');
        });
        if (encoded.length === 0) {
            throw new Error('fields cannot be empty');
        }
        return '{' + encoded.join(',') + '}';
    }
    encodeParams(params, feeds) {
        var encoded = this.encodeParamsMap(params);
        var feedsEncoded = this.encodeParamsMap(feeds);
        if (encoded.length === 0) {
            throw new Error('params cannot be empty');
        }
        var paramString = '(filter: {' + encoded.join(',') + '}';
        if (feedsEncoded.length > 0) {
            paramString += ', feed: {' + feedsEncoded.join(',') + '}';
        }
        paramString += ')';
        return paramString;
    }
    encodeParamsMap(params) {
        if (!params ||
            (typeof params === 'undefined' ? 'undefined' : this._typeof(params)) !==
                'object') {
            return {};
        }
        var keys = Object.keys(params).filter(function (key) {
            var val = params[key];
            return (params.hasOwnProperty(key) &&
                val !== undefined &&
                val !== null &&
                !Number.isNaN(val));
        });
        return keys.map((key) => this.encodeParam(key, params[key]));
    }
    encodeParam(key, val) {
        return key + ':' + this.encodeParamValue(val);
    }
    encodeParamValue(value) {
        if (Array.isArray(value)) {
            return this.encodeParamsArray(value);
        }
        if ((typeof value === 'undefined' ? 'undefined' : this._typeof(value)) ===
            'object') {
            return this.encodeParamsObject(value);
        }
        if (typeof value === 'string') {
            return JSON.stringify(value);
        }
        if (typeof value === 'number') {
            return String(value);
        }
        if (typeof value === 'boolean') {
            return value;
        }
        throw new Error('unsupported param type "' +
            (typeof value === 'undefined' ? 'undefined' : this._typeof(value)) +
            '"');
    }
    encodeParamsObject(params) {
        var encoded = this.encodeParamsMap(params);
        return '{' + encoded.join(',') + '}';
    }
    encodeParamsArray(array) {
        var encoded = array.map(this.encodeParamValue.bind(this));
        return '[' + encoded.join(',') + ']';
    }
}
GraphqlService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GraphqlService_Factory() { return new GraphqlService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(graphqlBaseUrl)); }, token: GraphqlService, providedIn: "root" });
