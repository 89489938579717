// Layouts
import { CommonLayoutComponent } from './common/common-layout.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';
import { AuthenticationGuard } from '@core/guards/authentication.guard';
import { AuthenticateURLGaurd } from '@core/guards/authenticateURL.gaurd';
import { PageNotFoundComponent } from '@app/common/page-not-found.component';
const ɵ0 = () => import("./courier/courier.module.ngfactory").then((m) => m.CourierModuleNgFactory), ɵ1 = () => import("./workforce/workforce.module.ngfactory").then((m) => m.WorkForceModuleNgFactory), ɵ2 = () => import("./outsource/outsource.module.ngfactory").then((m) => m.OutSourceModuleNgFactory), ɵ3 = () => import("./client-express-view/client-express-view.module.ngfactory").then((m) => m.ClientExpressViewModuleNgFactory), ɵ4 = () => import("./client-view/client-view.module.ngfactory").then((m) => m.ClientViewModuleNgFactory), ɵ5 = () => import("./marketplace/marketplace.module.ngfactory").then((m) => m.MarketplaceModuleNgFactory), ɵ6 = () => import("./eddress-book/eddress-book.module.ngfactory").then((m) => m.EddressBookModuleNgFactory), ɵ7 = () => import("./dispatcher/dispatcher.module.ngfactory").then((m) => m.DispatcherModuleNgFactory), ɵ8 = () => import("./billing/billing.module.ngfactory").then((m) => m.BillingModuleNgFactory), ɵ9 = { role: 'DISPATCHER' }, ɵ10 = () => import("./admin-settings/admin-settings.module.ngfactory").then((m) => m.AdminSettingsModuleNgFactory), ɵ11 = { role: 'INTERIM' }, ɵ12 = () => import("./interim-orders/interim-orders.module.ngfactory").then((m) => m.InterimOrdersModuleNgFactory), ɵ13 = () => import("./last-mile/last-mile.module.ngfactory").then((m) => m.LastMileModuleNgFactory), ɵ14 = () => import("./authentication/authentication.modules.ngfactory").then((m) => m.AuthenticationModuleNgFactory), ɵ15 = () => import("./server-error/server-error.module.ngfactory").then((m) => m.ServerErrorModuleNgFactory), ɵ16 = () => import("./access-denied/access-denied.module.ngfactory").then((m) => m.AccessDeniedModuleNgFactory);
export const AppRoutes = [
    {
        path: '',
        // canActivate: [AuthenticationGuard],
        redirectTo: 'portal/login',
        pathMatch: 'full'
    },
    {
        path: '',
        component: CommonLayoutComponent,
        canActivate: [AuthenticationGuard],
        canActivateChild: [NgxPermissionsGuard],
        children: [
            {
                path: 'courier',
                loadChildren: ɵ0
            },
            {
                path: 'workforce',
                loadChildren: ɵ1
            },
            {
                path: 'dashboard',
                loadChildren: ɵ2
            },
            {
                path: 'clientExpressView',
                loadChildren: ɵ3
            },
            {
                path: 'clientview',
                loadChildren: ɵ4
            },
            {
                path: 'marketplace',
                loadChildren: ɵ5
            },
            {
                path: 'eddress-book',
                loadChildren: ɵ6
            },
            {
                path: 'dispatcher',
                // canActivate: [AuthenticateURLGaurd],
                // data: { role: 'DISPATCHER' },
                loadChildren: ɵ7
            },
            {
                path: 'billing',
                loadChildren: ɵ8
            },
            {
                path: 'admin-settings',
                canActivate: [AuthenticateURLGaurd],
                data: ɵ9,
                loadChildren: ɵ10
            },
            {
                path: 'interim-orders',
                canActivate: [AuthenticateURLGaurd],
                data: ɵ11,
                loadChildren: ɵ12
            },
            {
                path: 'last-mile',
                // canActivate: [AuthenticateURLGaurd],
                // data: { role: 'INTERIM' },
                loadChildren: ɵ13
            }
        ]
    },
    {
        path: '',
        component: AuthenticationLayoutComponent,
        children: [
            {
                path: 'portal',
                loadChildren: ɵ14
            }
        ]
    },
    {
        path: '',
        component: AuthenticationLayoutComponent,
        children: [
            {
                path: 'server-error',
                loadChildren: ɵ15
            }
        ]
    },
    {
        path: '',
        component: AuthenticationLayoutComponent,
        children: [
            {
                path: 'access-denied',
                loadChildren: ɵ16
            }
        ]
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16 };
