import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as i0 from "@angular/core";
export class MessagesService {
    constructor() {
        this.internalServerErrorMessage = new BehaviorSubject('Uh Sorry, something went');
        this.serverErrorMessage = this.internalServerErrorMessage.asObservable();
    }
    changeServerErrorMessage(message) {
        this.internalServerErrorMessage.next(message);
    }
}
MessagesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessagesService_Factory() { return new MessagesService(); }, token: MessagesService, providedIn: "root" });
