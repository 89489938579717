import { Injectable } from '@angular/core';
import { ToastyService } from 'ng2-toasty';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	constructor(private toastyService: ToastyService) {}

	errorNotification(message: string) {
		this.toastyService.error({
			title: '',
			msg: message,
			showClose: true,
			timeout: 5000,
			theme: 'default'
		});
	}

	successNotification(message: string) {
		this.toastyService.success({
			title: '',
			msg: message,
			showClose: true,
			timeout: 5000,
			theme: 'default'
		});
	}

	infoNotification(message: string) {
		this.toastyService.info({
			title: '',
			msg: message,
			showClose: true,
			timeout: 5000,
			theme: 'default'
		});
	}

	waitNotification(message: string) {
		this.toastyService.wait({
			title: '',
			msg: message,
			showClose: true,
			timeout: 5000,
			theme: 'default'
		});
	}

	warningNotification(message: string) {
		this.toastyService.warning({
			title: '',
			msg: message,
			showClose: true,
			timeout: 5000,
			theme: 'default'
		});
	}

	playSound() {
		const audio = new Audio();
		audio.src = '../../../assets/audio/success.mp3';
		audio.load();
		const playPromise = audio.play();
		if (playPromise !== null) {
			playPromise.catch(() => {
				audio.play();
			});
		}
	}

	playWarningSound() {
		const audio = new Audio();
		audio.src = '../../../assets/audio/warning.wav';
		audio.load();
		audio.play();
	}
}
