import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as i0 from "@angular/core";
export class ReactiveFormService {
    constructor() { }
    isFieldValid(form, field) {
        return !form.get(field).valid && form.get(field).touched;
    }
    validateAllFormFields(formGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }
    setsValidators(control, required) {
        if (required) {
            control.setValidators([Validators.required]);
        }
        else {
            control.setValidators(null);
        }
        control.updateValueAndValidity();
    }
}
ReactiveFormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReactiveFormService_Factory() { return new ReactiveFormService(); }, token: ReactiveFormService, providedIn: "root" });
