import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class ReactiveFormService {
	constructor() {}

	isFieldValid(form: FormGroup, field: string) {
		return !form.get(field).valid && form.get(field).touched;
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach((field) => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}

	setsValidators(control, required: boolean) {
		if (required) {
			control.setValidators([Validators.required]);
		} else {
			control.setValidators(null);
		}
		control.updateValueAndValidity();
	}
}
