import { Injector } from '@angular/core';
import { AuthService } from './auth.service';
import { UtilityService } from './utility.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { UIStateService } from './uiState.service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/internal/operators';
import { ApiService } from './api.service';
import { NotificationService } from './notification.service';
import { Title } from '@angular/platform-browser';
import { ReactiveFormService } from '@core/services/reactiveForm.service';
import { AppRoutesEnum } from '@core/models/appRoutes.enum';
import { UserRolesEnum } from '@core/models/userRoles.enum';
import { COLLECTIONS, COLLECTIONS_ALL, MARKET_ADMIN_CONFIG, VALIDATE_PHONE_NUMBER } from '../utilities/api-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "@angular/platform-browser";
export class EddressFacadeService {
    constructor(_document, injector, router, titleService) {
        this._document = _document;
        this.injector = injector;
        this.router = router;
        this.titleService = titleService;
        this.appURLEnum = AppRoutesEnum;
        this.userRolesEnum = UserRolesEnum;
    }
    get authService() {
        if (!this._authService) {
            this._authService = this.injector.get(AuthService);
        }
        return this._authService;
    }
    get api() {
        if (!this._api) {
            this._api = this.injector.get(ApiService);
        }
        return this._api;
    }
    get utilityService() {
        if (!this._utilityService) {
            this._utilityService = this.injector.get(UtilityService);
        }
        return this._utilityService;
    }
    get uiStateService() {
        if (!this._uiStateService) {
            this._uiStateService = this.injector.get(UIStateService);
        }
        return this._uiStateService;
    }
    get reactiveFormService() {
        if (!this._reactiveFormService) {
            this._reactiveFormService = this.injector.get(ReactiveFormService);
        }
        return this._reactiveFormService;
    }
    get modalService() {
        if (!this._modalService) {
            this._modalService = this.injector.get(NgbModal);
        }
        return this._modalService;
    }
    get notify() {
        if (!this._notify) {
            this._notify = this.injector.get(NotificationService);
        }
        return this._notify;
    }
    setDescription(description) {
        this.description = description;
    }
    setCompanyLogo(logo) {
        this.companyLogo = logo;
    }
    setLogoIcon(logo) {
        this.logoIcon = logo;
    }
    setBackgroundImage(image) {
        this.backgroundImage = image;
    }
    setBackgroundColor(color) {
        this.backgroundColor = color;
    }
    setAppFavicon(icon) {
        this._document.getElementById('appFavicon').setAttribute('href', icon);
    }
    getDescription() {
        return this.description;
    }
    getCompanyLogo() {
        if (!this.companyLogo) {
            this.companyLogo = localStorage.getItem('companyLogo');
        }
        return this.companyLogo;
    }
    getLogoIcon() {
        return this.logoIcon;
    }
    getBackgroundImage() {
        return this.backgroundImage;
    }
    getBackgroundColor() {
        return this.backgroundColor;
    }
    getCurrentUser() {
        return this.authService.currentUser;
    }
    getIsMenuCollapsed() {
        return this.uiStateService.isMenuCollapsed;
    }
    attemptAuth(username, password) {
        return this.authService.attemptAuth(username, password);
    }
    companyConfiq(companyName) {
        return this.api
            .get('portal/fieldforce/common/companyInfo/' + companyName)
            .pipe(map((data) => {
            return data;
        }));
    }
    forgotPassword(username) {
        return this.api
            .post('portal/common/user/forgotPassword', { userName: username })
            .pipe(map((data) => {
            return data;
        }));
    }
    verifyUser(token) {
        return this.api.get('portal/common/user/register/' + token).pipe(map((data) => {
            return data;
        }));
    }
    changePassword(token, password) {
        return this.api
            .post('portal/common/user/changePassword', {
            token: token,
            password: password
        })
            .pipe(map((data) => {
            return data;
        }));
    }
    populateUser() {
        return this.authService.populateUser();
    }
    getCities() {
        return this.api.get('v1/area/cities').pipe(map((data) => {
            return data;
        }));
    }
    getCitiesFromMongo() {
        return this.api
            .get('portal/common/constants/cities')
            .pipe(map((data) => data));
    }
    setCompanyInfo(data) {
        const companyIfo = data.companyInfo;
        this.setDescription(companyIfo.description);
        this.setCompanyLogo(companyIfo.companyLogo);
        this.setLogoIcon(companyIfo.logoIcon);
        this.setBackgroundImage(companyIfo.backgroundImage);
        this.setBackgroundColor(companyIfo.backgroundColor);
        if (companyIfo.favIcon) {
            this.setAppFavicon(companyIfo.favIcon);
            localStorage.setItem('favIcon', companyIfo.favIcon);
        }
        if (companyIfo.title) {
            this.titleService.setTitle(companyIfo.title);
            localStorage.setItem('title', companyIfo.title);
        }
        localStorage.setItem('companyLogo', companyIfo.companyLogo);
        localStorage.setItem('logoIcon', companyIfo.logoIcon);
    }
    errorNotification(warning) {
        return this.notify.errorNotification(warning);
    }
    successNotification(warning) {
        return this.notify.successNotification(warning);
    }
    infoNotification(warning) {
        return this.notify.infoNotification(warning);
    }
    warningNotification(warning) {
        return this.notify.warningNotification(warning);
    }
    waitNotification(warning) {
        return this.notify.waitNotification(warning);
    }
    getWorkers() {
        return this.api.get('portal/fieldforce/common/workers').pipe(map((data) => {
            return data;
        }));
    }
    getCourierWorkers() {
        const params = {
            'workerList': true
        };
        return this.api.get('v1/workers', params).pipe(map((data) => {
            return data;
        }));
    }
    getCustomers() {
        return this.api.get('portal/fieldforce/common/customers').pipe(map((data) => {
            return data;
        }));
    }
    getTeams() {
        return this.api.get('portal/fieldforce/common/team').pipe(map((data) => {
            return data;
        }));
    }
    getZones() {
        return this.api.get('portal/fieldforce/common/zoneTurf').pipe(map((data) => {
            return data;
        }));
    }
    getWarehoues() {
        return this.api.get('portal/fieldforce/common/warehouses').pipe(map((data) => {
            return data;
        }));
    }
    getVehicleTypes() {
        return this.api
            .get('portal/clientView/thirdPartyVehicleTypes')
            .pipe(map((data) => data));
    }
    cancelOrders(params) {
        return this.api
            .put('portal/fieldforce/lastmile/orders/cancelOrders', params)
            .pipe(map((data) => data));
    }
    getCancelReasons() {
        return this.api
            .get('market/orders/cancellationReasons')
            .pipe(map((data) => data));
    }
    getStores() {
        return this.api.get('market/orders/stores').pipe(map((data) => {
            return data;
        }));
    }
    getCountries() {
        return this.api.get('portal/common/constants/countries').pipe(map((data) => {
            return data;
        }));
    }
    getCategories(companyUid) {
        return this.api
            .get('market/products/categories?thirdPartyUid=' + companyUid)
            .pipe(map((data) => {
            return data;
        }));
    }
    getAllMarketCollections() {
        return this.api.get(`${COLLECTIONS_ALL}`).pipe(map((data) => {
            return data;
        }));
    }
    getAllMarketCollectionsByThirdPartyUid(thirdPartyUid) {
        return this.api.get(`${COLLECTIONS}?thirdPartyUid=${thirdPartyUid}`).pipe(map((data) => {
            return data;
        }));
    }
    getUsersConfigurations() {
        return this.api.get('v1/marketplace/configurations').pipe(map((data) => {
            return data;
        }));
    }
    getAllCities() {
        return this.api
            .get('v1/area/cities')
            .pipe(map((data) => {
            return data;
        }));
    }
    getSettingCountries() {
        return this.api.get('v1/settings/getCountries').pipe(map((data) => {
            return data;
        }));
    }
    getAppConfig(thirdPartyUid) {
        return this.api.get(MARKET_ADMIN_CONFIG + thirdPartyUid).pipe(map((data) => {
            return data.operationConfig;
        }), catchError(error => {
            return of(null);
        }));
    }
    getOperationConfig() {
        const operationConfigJson = localStorage.getItem('operationConfig');
        if (operationConfigJson !== null) {
            return JSON.parse(operationConfigJson);
        }
        else {
            return null;
        }
    }
    getMarketPlaceConfigurations() {
        return this.api.get('v1/marketplace/configurations').pipe(map((data) => {
            return data;
        }));
    }
    validatePhoneNumber(phoneNumber, countryIso) {
        return this.api.get(`${VALIDATE_PHONE_NUMBER}?phoneNumber=${phoneNumber}&&countryIso=${countryIso}`);
    }
}
EddressFacadeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EddressFacadeService_Factory() { return new EddressFacadeService(i0.ɵɵinject(i1.DOCUMENT), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Title)); }, token: EddressFacadeService, providedIn: "root" });
