import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticatedLoginGuard } from '@core/guards/authenticatedLogin.guard';
import { AuthenticationGuard } from '@core/guards/authentication.guard';
import { DashboardRedirectGuard } from '@core/guards/dashboardRedirect.guard';
import { TokenInterceptorProvider } from './interceptors/token.interceptor';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastyModule } from 'ng2-toasty';
import { AuthenticateURLGaurd } from '@core/guards/authenticateURL.gaurd';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Sidebar_Directives } from '@shared/directives/side-nav.directive';
import { EntryComponentFactoryResolver } from '@app/entry-component-factory-resolver.service';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

export function throwExceptionIfCoreAlreadyLoaded(
	parentModule: any,
	moduleName: string
) {
	if (parentModule) {
		throw new Error(
			`${moduleName} has already been loaded. Import core module in the App module only.`
		);
	}
}
@NgModule({
	imports: [
		CommonModule,
		NgxPermissionsModule.forRoot(),
		ToastyModule.forRoot(),
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		})
	],
	declarations: [Sidebar_Directives],
	providers: [
		TokenInterceptorProvider,
		AuthenticationGuard,
		AuthenticatedLoginGuard,
		DashboardRedirectGuard,
		AuthenticateURLGaurd,
		EntryComponentFactoryResolver
	],
	exports: [
		NgxPermissionsModule,
		ToastyModule,
		HttpClientModule,
		Sidebar_Directives
	]
})
export class CoreModule {
	constructor(
		@Optional() @SkipSelf() parentModule: CoreModule,
		entryComponentResolver: EntryComponentFactoryResolver
	) {
		throwExceptionIfCoreAlreadyLoaded(parentModule, 'CoreModule');
		entryComponentResolver.init();
	}
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
