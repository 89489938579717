/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./page-not-found.component";
var styles_PageNotFoundComponent = [];
var RenderType_PageNotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 21, "div", [["class", "authentication"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 20, "div", [["class", "page-404 container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 19, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 13, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 12, "div", [["class", "full-height"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 11, "div", [["class", "vertical-align full-height pdd-horizon-70"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 10, "div", [["class", "table-cell"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h1", [["class", "text-dark font-size-80 text-light"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Oops!"])), (_l()(), i0.ɵeld(9, 0, null, null, 3, "p", [["class", "lead lh-1-8"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Something went wrong. But don't worry,"])), (_l()(), i0.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["we'll get you back on track!"])), (_l()(), i0.ɵeld(13, 0, null, null, 3, "a", [["class", "btn btn-warning"], ["href", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(15, 1), (_l()(), i0.ɵted(-1, null, ["Get Me Back!"])), (_l()(), i0.ɵeld(17, 0, null, null, 4, "div", [["class", "col-md-5 ml-auto hidden-sm hidden-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 3, "div", [["class", "full-height height-100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 2, "div", [["class", "vertical-align full-height"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 1, "div", [["class", "table-cell"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 0, "img", [["alt", ""], ["class", "img-responsive"], ["src", "assets/images/others/404.png"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = _ck(_v, 15, 0, "/"); _ck(_v, 14, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 14).target; var currVal_1 = i0.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_0, currVal_1); }); }
export function View_PageNotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i0.ɵdid(1, 114688, null, 0, i3.PageNotFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageNotFoundComponentNgFactory = i0.ɵccf("app-dashboard", i3.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
